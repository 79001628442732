
<div>
  <div class="is-flex is-justify-content-space-between px-3">
    <span class="has-text-weight-bold">Quote Summary</span>
    <span class="tag">Zipcode {{ quote.zipcodeId }}</span>
    <!-- <span class="has-text-danger is-clickable" @click="">Clear</span> -->
  </div>
  <hr class="title-divider" />
  <div v-if="quote && quote.totalQuantity === 0" class="py-5 has-text-centered">
    <h5 class="title is-5 mb-2">Your quote is empty.</h5>
    <p>Call us at {{ phoneNumber }} to speak with a representative.</p>
  </div>
  <div v-else>
    <table class="table is-borderless is-fullwidth is-marginless mb-2">
      <quote-item
        v-for="item in quote.items"
        :key="item.id"
        :item="item"
        :showPrice="showPrice"
      ></quote-item>
    </table>
    <quote-totals />
    <div class="px-3">
      <button
        :class="{ 'disabled': invalid, 'is-loading': loading }"
        :disabled="invalid || loading > 0"
        class="button is-primary is-fullwidth is-medium"
        @click="handleFinishQuote()"
      >
        Finish Quote
      </button>
    </div>
  </div>
</div>
